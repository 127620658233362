import React from "react";
import Layout from "../layout/site-layout";
import Return from "../components/return-button";
import Title from "../components/title";
import Content from "../components/content";

const Page404 = () => (
  <Layout>
    <Title text="Oops" />
    <Content text="Wir haben ein Problem..." />
    <Return />
  </Layout>
);

export default Page404;
