import React from "react";
import { css } from "@emotion/core";

const Content = ({ text }) => (
  <p
    css={css`
      ${textStyles}
    `}
  >
    {text}
  </p>
);

export default Content;

const textStyles = css`
  font-size: 18px;
  line-height: 26px;
  margin-top: 1rem;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }

  p {
    margin-bottom: 10px;
  }
`;
